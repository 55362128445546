import React, { Component } from "react"
import PropTypes from "prop-types"
import { Box } from "../Styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faCheck, faInfo, faExclamationCircle, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import { is } from "ramda"

const typesMap = {
  info: {
    backgroundColor: "ninjaBlueDark",
    textColor: "white",
    icon: faInfo,
  },
  success: {
    backgroundColor: "ninjaBlueDark",
    textColor: "white",
    icon: faCheck,
  },
  warning: {
    backgroundColor: "ninjaYellow",
    textColor: "black",
    icon: faExclamationCircle,
  },
  error: {
    backgroundColor: "ninjaRed",
    textColor: "white",
    icon: faExclamationTriangle,
  },
}

class AlertMessage extends Component {
  static defaultProps = {
    id: "",
    time: 0,
    icon: null,
    message: "",
    type: "info",
    duration: 0,
    removeAlert: () => {},
  }

  static propTypes = {
    id: PropTypes.string,
    icon: PropTypes.object,
    time: PropTypes.number,
    duration: PropTypes.number,
    remove: PropTypes.func,
    type: PropTypes.oneOf(["info", "success", "warning", "error"]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }

  componentDidMount() {
    const { id, duration, removeAlert } = this.props

    if (duration > 0) {
      setTimeout(() => removeAlert(id), duration)
    }
  }

  render() {
    const { id, message, type, removeAlert } = this.props

    return (
      <Box
        display="flex"
        width={400}
        minHeight={60}
        backgroundColor={typesMap[type].backgroundColor}
        color={typesMap[type].textColor}
        marginBottom={4}
        opacity={0.95}
        borderRadius={3}
        role="alert"
      >
        <Box padding={[0, 5]} width={35} display="flex" alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={typesMap[type].icon} size="lg" />
        </Box>

        <Box padding={[2, 0]} flex={1} display="flex" alignItems="center" justifyContent="flex-start" fontSize={14}>
          {is(Function, message) ? message() : message}
        </Box>

        <Box
          padding={[0, 4]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => removeAlert(id)}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Box>
      </Box>
    )
  }
}

export default AlertMessage
