import { useCallback, useMemo } from "react"
import { connect } from "react-redux"

import { updatePromotionBannerToNotShow } from "js/includes/common/client"
import PromoCarousel from "js/includes/components/MarketingPromoPopup/components/PromoCarousel"
import { Box } from "js/includes/components/Styled"
import { hideMarketingPromo } from "js/state/actions/marketingPromo/marketingPromo"

const MarketingPromoPopup = ({ marketingPromo, hideMarketingPromo, appUserId }) => {
  const handleClosePopup = async () => {
    const promosIds = marketingPromo.map(name => name.id)
    await updatePromotionBannerToNotShow(promosIds)
    hideMarketingPromo()
  }

  const generateMarketingData = useCallback(
    data => {
      return data.map(({ onRemindMeLater: remindMeLater, onLearnMore: learnMore, ...rest }) => ({
        ...rest,
        onLearnMore:
          learnMore &&
          (() => {
            learnMore()
            hideMarketingPromo(rest.id)
            updatePromotionBannerToNotShow(rest.id)
          }),
        onRemindMeLater:
          remindMeLater &&
          (() => {
            remindMeLater()
            hideMarketingPromo(rest.id)
            updatePromotionBannerToNotShow(rest.id)
          }),
      }))
    },
    [hideMarketingPromo],
  )

  const marketingPromoData = useMemo(() => (marketingPromo?.length ? generateMarketingData(marketingPromo) : null), [
    generateMarketingData,
    marketingPromo,
  ])

  if (!marketingPromoData) return null

  return (
    <Box
      className="fadeIn fadeOut"
      zIndex={999999999}
      display="flex"
      flexDirection="column"
      position="fixed"
      right={20}
      bottom={20}
    >
      <PromoCarousel promoItems={marketingPromoData} handleClose={handleClosePopup} />
    </Box>
  )
}

const mapStateToProps = ({ marketingPromo, session }) => ({
  marketingPromo,
})

export default connect(mapStateToProps, {
  hideMarketingPromo,
})(MarketingPromoPopup)
