import styled from "@emotion/styled"

import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"

import { useMountedState } from "js/includes/common/hooks"
import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { StyledButton } from "js/includes/components/Styled/Form"

import { PromoCard } from "./PromoCard"

const transition = "0.5s cubic-bezier(0.39, 0.575, 0.565, 1)"

const StyledCarousel = styled(Box)`
  min-height: 400px;
  width: 350px;
  padding-bottom: ${sizer(6)};
  border-radius: ${sizer(1)};
  background-color: ${({ theme }) => theme.palette.base};
  box-shadow: ${({ theme }) => theme.color.elevation.shadow4};
`

const StyledItem = styled(Box)`
  min-width: 100%;
  transition: ${transition};
`

const StyledTextButton = styled(StyledButton)`
  position: relative;
  display: flex;
  gap: ${sizer(2)};
  align-items: center;
  font-weight: 500;
  font-size: ${getTextSize("sm")};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`

const Dot = styled(Box)`
  width: ${sizer(2)};
  height: ${sizer(2)};
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.color.black["075"] : theme.color.black["025"])};
  transition: ${transition};
`

const CloseButton = styled.button`
  padding: 0;
  outline: 0;
  background: none;
  border: none;
  position: absolute;
  z-index: 1;
  top: ${sizer(4)};
  right: ${sizer(4)};
  height: 1rem;

  &:focus-within {
    outline: 2px auto ${({ theme }) => theme.color.black["100"]};
  }
`

const PromoCarousel = ({ promoItems, handleClose }) => {
  const [currentIndex, setCurrentIndex] = useMountedState(0)
  const handleBack = () => setCurrentIndex(prevState => Math.max(prevState - 1, 0))
  const handleNext = () => setCurrentIndex(prevState => Math.min(prevState + 1, promoItems.length - 1))
  const isMultiplePromotion = promoItems.length > 1

  return (
    <StyledCarousel>
      <CloseButton id="fs-promo-close-btn" type="button" onClick={handleClose}>
        <CloseIcon color="inputText" />
      </CloseButton>
      <Flex flexWrap="nowrap" overflow="hidden">
        {promoItems.map((promoData, index) => (
          <StyledItem key={promoData.id} style={{ transform: `translate(-${currentIndex * 100}%)` }}>
            <PromoCard promoData={promoData} withFrame={false} showFooter={!isMultiplePromotion} />
          </StyledItem>
        ))}
      </Flex>
      {isMultiplePromotion && (
        <>
          <Flex alignItems="center" justifyContent="center" gap={sizer(2)} margin={sizer(6, 0, 3, 0)}>
            {promoItems.map((promoData, index) => (
              <Dot key={promoData.id} active={currentIndex === index} />
            ))}
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" padding={sizer(0, 6)}>
            <StyledTextButton visible={currentIndex > 0} onClick={handleBack}>
              <ArrowLeftIcon size="sm" /> {localized("Back")}
            </StyledTextButton>
            <StyledTextButton visible={currentIndex < promoItems.length - 1} onClick={handleNext}>
              {localized("Next")} <ArrowRightIcon size="sm" />
            </StyledTextButton>
          </Flex>
        </>
      )}
    </StyledCarousel>
  )
}

export default PromoCarousel
