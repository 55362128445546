import { HelpIcon } from "@ninjaone/icons"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import Tooltip from "./Tooltip"

export default function InfoTooltip({ token, text, ariaLabel }) {
  return (
    <Tooltip label={text ?? localized(token)} ariaLabel={ariaLabel ?? localized("More Information")}>
      <HelpIcon size="xs" />
    </Tooltip>
  )
}
