import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Body, Tooltip } from "@ninjaone/components"
import { typography } from "@ninjaone/tokens/typography"

function getInitials(name) {
  if (!name) return ""
  if (name.length === 2) return name

  const initials = name
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join("")

  return initials.substring(0, 2)
}

const getStylesBySize = size => {
  switch (size) {
    case "md":
      return {
        width: "38px",
        height: "38px",
        type: "body",
      }

    case "lg":
      return {
        width: "48px",
        height: "48px",
        type: "headingS",
      }

    default:
      return {
        width: "28px",
        height: "28px",
        type: "bodyXs",
      }
  }
}

const StyledStackedAvatars = styled.div`
  display: flex;
  cursor: default;

  button {
    border: 2px solid ${({ theme }) => theme.colorBackground};
  }

  > :not(:first-of-type) {
    margin-left: -4px;
  }
`

const StyledAvatarButton = styled.button`
  all: unset;
  position: relative;
  cursor: ${({ hasAction }) => (hasAction ? "pointer" : "default")};

  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  color: ${({ theme }) => theme.colorTextStrong};
  z-index: ${({ zIndex }) => zIndex};

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => {
    const { width, height } = getStylesBySize(size)

    return `
      width: ${width};
      height: ${height};
    `
  }}

  border-radius: 50%;
  text-transform: uppercase;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledAvatar = StyledAvatarButton.withComponent("div")

function AvatarInitials({ name, customText, size, onAvatarClick, customTooltipLabel, zIndex, hasAction }) {
  const nameInitials = getInitials(name)
  const StyledAvatarElement = onAvatarClick ? StyledAvatarButton : StyledAvatar

  return (
    <Tooltip label={customTooltipLabel || name}>
      <StyledAvatarElement {...{ onClick: () => onAvatarClick?.(name, nameInitials), size, zIndex, hasAction }}>
        <Body
          {...{ color: "colorTextStrong", fontWeight: typography.fontWeight.medium, type: getStylesBySize(size).type }}
        >
          {customText || nameInitials}
        </Body>
      </StyledAvatarElement>
    </Tooltip>
  )
}

function Avatar({ data, size, onAvatarClick, avatar }) {
  if (avatar || typeof data === "string") {
    return <AvatarInitials {...{ name: data || avatar, size, onAvatarClick }} />
  }

  const shownNames = data.slice(0, 3)
  const hiddenNames = data.slice(3, data.length)

  const hasAction = !!onAvatarClick

  function handleOnAvatarClickForHiddenNames() {
    onAvatarClick?.(
      hiddenNames,
      hiddenNames.map(hiddenName => getInitials(hiddenName)),
    )
  }

  return (
    <StyledStackedAvatars>
      {shownNames.map((name, index) => (
        <AvatarInitials
          {...{ key: `${name}-${index}`, name, size, onAvatarClick, zIndex: shownNames.length - index, hasAction }}
        />
      ))}

      {hiddenNames.length > 0 && (
        <AvatarInitials
          {...{
            size,
            hasAction,
            customText: `+${hiddenNames.length}`,
            customTooltipLabel: hiddenNames.join(",\n"),
            onAvatarClick: handleOnAvatarClickForHiddenNames,
          }}
        />
      )}
    </StyledStackedAvatars>
  )
}

Avatar.defaultProps = {
  size: "sm",
}

Avatar.propTypes = {
  /**
   * Deprecated - Please use data instead
   * @deprecated - use data instead
   */
  avatar: PropTypes.string,
  /**
   * A function to be called when the avatar is clicked.
   * It receives two parameters: the name of the user and their name initials.
   */
  onAvatarClick: PropTypes.func,
  /**
   * The size of the avatar.
   */
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  /**
   *  The data for the avatar. It can be either a single string or an array of strings.
   */
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
}

export default Avatar
