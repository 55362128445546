import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import AlertsContainer from "./AlertsContainer"

export const initializeAlerts = () => {
  ReactDOM.render(
    <Provider store={window.store}>
      <AlertsContainer />
    </Provider>,
    document.getElementById("alert-messages"),
  )
}
