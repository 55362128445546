import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@emotion/react"
import theme from "@ninjaone/webapp/src/js/includes/common/theme/theme-one/light"
import MarketingPromoPopup from "./MarketingPromoPopup"

export const initializeMarketingPromo = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={window.store}>
        <MarketingPromoPopup />
      </Provider>
    </ThemeProvider>,
    document.getElementById("marketing-promo"),
  )
}
