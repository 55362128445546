import React, { Component } from "react"
import { connect } from "react-redux"
import AlertMessage from "./AlertMessage"
import { Box } from "../Styled"
import { addAlert, removeAlert, removeAllAlerts } from "js/state/actions/alerts/alertMessages"

class AlertContainer extends Component {
  render() {
    const { alerts, addAlert, removeAlert, removeAllAlerts } = this.props
    return (
      <Box zIndex={999999999} display="flex" flexDirection="column" position="fixed" right={20} bottom={20}>
        {alerts.map(alert => {
          return (
            <AlertMessage
              key={alert.id}
              {...{
                addAlert,
                removeAlert,
                removeAllAlerts,
                ...alert,
              }}
            />
          )
        })}
      </Box>
    )
  }
}

const mapStateToProps = ({ alerts }) => ({
  alerts,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addAlert: alert => dispatch(addAlert(alert)),
  removeAlert: alertId => dispatch(removeAlert(alertId)),
  removeAllAlerts: () => dispatch(removeAllAlerts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer)
