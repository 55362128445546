import * as FullStory from "@fullstory/browser"

const fullstoryEnabledEnvs = ["app"]

export function isFullstoryEnabledEnv(environment) {
  const isNotAutomation = !window["%hammerhead%"]
  return (
    environment &&
    process.env.NODE_ENV === "production" &&
    fullstoryEnabledEnvs.includes(environment) &&
    isNotAutomation
  )
}

export function initializeFullstory({ environment, sessionProperties }) {
  const isFullstoryEnabled = isFullstoryEnabledEnv(environment)
  const { features, divisionUid, userType, user, accountCreatedDate, isTrialLicense } = sessionProperties
  const isBranded = window.store.getState().application.isBranded
  const isNinjaEmployee = ["ninjamsp", "ninjarmm", "ninjaone"].some(ninjaDomain =>
    user.email.toLowerCase().includes(ninjaDomain),
  )

  if (isFullstoryEnabled) {
    try {
      window["_fs_ready"] = function() {
        FullStory.identify(user.externalUid, {
          /* If adding user information here- Approval is needed from our Security & Privacy team */
          features: features?.join(","),
          divisionUid,
          userType,
          isBranded,
          accountCreatedDate,
          isTrialLicense,
          isNinjaEmployee,
        })
      }
      FullStory.init({ orgId: "10C6BF" })
    } catch (error) {
      return false
    }
  }

  return isFullstoryEnabled
}
