import { nanoid } from "nanoid"

export const addAlert = alert => dispatch => {
  const id = nanoid(10)

  dispatch({
    type: "ADD_ALERT",
    alert: {
      id,
      time: Date.now(),
      ...alert,
    },
  })

  return id
}

export const removeAlert = alertId => dispatch =>
  dispatch({
    type: "REMOVE_ALERT",
    alertId,
  })

export const removeAllAlerts = () => dispatch =>
  dispatch({
    type: "REMOVE_ALL_ALERTS",
  })
