import styled from "@emotion/styled"

import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { colors } from "js/includes/common/theme"
import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { StyledButton } from "js/includes/components/Styled/Form"

const CardStyled = styled.div`
  position: relative;
  max-width: 350px;
  background-color: ${({ theme }) => theme.palette.base};
  box-shadow: ${({ theme, withFrame }) => (withFrame ? theme.color.elevation.shadow4 : "")};
  border-radius: ${sizer(1)};
`

const Cover = styled(Flex)`
  height: 200px;
  align-items: center;
  justify-content: center;
  border-radius: ${sizer(1)};
  background-color: ${({ theme, coverBackground }) => coverBackground || theme.color.primary["025"]};

  img {
    width: 240px;
    height: 130px;
    object-fit: contain;
  }
`

const ActionContainerStyled = styled.div`
  display: flex;
  gap: ${sizer(4)};
  justify-content: flex-end;
  margin-top: ${sizer(6)};
`
export const PromoCard = ({
  promoData: { onRemindMeLater, onLearnMore, IconComponent, titleToken, descriptionToken, coverUrl, coverBackground },
  withFrame = true,
  showFooter = true,
}) => (
  <CardStyled {...{ withFrame }}>
    {coverUrl && (
      <Cover {...{ coverBackground }}>
        <img src={coverUrl} alt={localized(titleToken)} />
      </Cover>
    )}
    <Box padding={sizer(6, 6, 0, 6)}>
      <Flex gap={sizer(2)} alignItems="center" marginBottom={sizer(4)}>
        {IconComponent && <IconComponent />}
        {titleToken && <Text bold size="md" color="inputText" token={titleToken} textWrap textWrapLineLimit={2} />}
      </Flex>
      <Text textWrap size="sm">
        {localized(descriptionToken)}{" "}
        {!showFooter && onLearnMore && <StyledButton onClick={onLearnMore}>{localized("Learn more")}</StyledButton>}
      </Text>
      {showFooter && (
        <ActionContainerStyled>
          {onRemindMeLater && (
            <StyledButton fontWeight={500} color={colors.ninjaBlack} onClick={onRemindMeLater}>
              {localized("Remind me later")}
            </StyledButton>
          )}
          {onLearnMore && (
            <StyledButton fontWeight={500} onClick={onLearnMore}>
              {localized("Learn more")}
            </StyledButton>
          )}
        </ActionContainerStyled>
      )}
    </Box>
  </CardStyled>
)
